/* .card {
  box-shadow: 2px 1px 5px black;
} */

body {
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.3)); */
  /* background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%); */
  background-color: #85FFBD;
  background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

/* .border: {
  border: 1px solid black;
} */

.SUCCESS-log {
  color: springGreen;
}

.INFO-log {
  color: royalBlue;
}

.ERROR-log {
  color: red;
}
